import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import HdxxCardUtil,{IHdxxCardDataObj} from './hdxxCardUtil';
import DcwtdCard from "@/views/project/order/fy/zl/dcwtd/DcwtdCard.vue";
export default defineComponent({
    name:'HdxxCard',
    title:'货代信息',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IHdxxCardDataObj=reactive<IHdxxCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/hdxx')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                tkType: [utils.UtilPub.commonValidRule(proxy.$t('hdxx.form.tkType_rule'))],
                dcType: [utils.UtilPub.commonValidRule(proxy.$t('hdxx.form.dcType_rule'))],
            },
            otherParams:{
                hdxxData:[],//货代信息下拉
                customerData:[],//启票下拉
                hdgsData:[],//货代公司下拉
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new HdxxCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'tkType'==params.comboId){
                    return [{value:0,label:'FOB条款'},{value:1,label:'C&F条款'},{value:2,label:'其它'}]
                }
                if(params && 'dcType'==params.comboId){
                    return [{value:0,label:'集装箱订舱'},{value:1,label:'滚装订舱'},{value:2,label:'其它'}]
                }
                if(params && 'hdgsId'==params.comboId){
                    return dataObj.otherParams.hdgsData;
                }
                if(params && 'hdxxId'==params.comboId){
                    return dataObj.otherParams.hdxxData;
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,selectId:string)=>{
            if('tkType'==selectId){}
            if('dcType'==selectId){}
            if('hdgsId'==selectId){}
            if('hdxxId'==selectId){
                if(newValue && newValue.value!=dataObj.form.id){//选择的是自己，则不管了，否则用其值填充当前表单
                    let item=Object.assign({},newValue.item);
                    //以下是不需要填充的属性，把它冲item中删除，然后再把item赋值给form
                    let delProps = ['id','hdxxId','customerId','dzh','date','sysAddUser','sysLastTime','sysLastUser','sysVersion'];
                    for (let prop of delProps)delete item[prop];
                    Object.assign(dataObj.form,item);
                }
            }
            if('customerId'==selectId){
                dataObj.form.hdgsId='';
                if(newValue){
                    let resultData=await utils.OrderProviderApi.getCustomerHdgs({params:{customerId:newValue}});
                    dataObj.otherParams.hdgsData=resultData.resultData;
                    if(!dataObj.form.shrGsmc && !dataObj.form.shrLxdh && !dataObj.form.shrLxdz){
                        let customerBean=dataObj.otherParams.customerData.find((item:any)=>item.value==newValue);
                        if(customerBean){
                            dataObj.form.shrGsmc=customerBean.item.companyName;
                            dataObj.form.shrLxdh=customerBean.item.contact;
                            dataObj.form.shrLxdz=customerBean.item.contactAddress;
                        }
                    }
                }
            }
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/add'==addOrLoad){

            }else{
                //货代公司
                let resultData=await utils.OrderProviderApi.getCustomerHdgs({params:{customerId:dataObj.form.customerId}});
                dataObj.otherParams.hdgsData=resultData.resultData;

                if(res.data.hdxxId)dataObj.form.hdxxId=dataObj.otherParams.hdxxData.find((item:any)=>item.value==res.data.hdxxId)
                if(res.data.flag==1)dataObj.disabled=true;
            }
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            //其实只有新增的时候，后台才会用到下面的参数
            return {childOrderId:engineInst.engineParams.childOrderId,cardFrom:engineInst.engineParams.cardFrom};
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(dataObj.form.hdxxId)dataObj.form.hdxxId=dataObj.form.hdxxId.value;//这个是对象，所以存的时候要取值来存储。
            return true;
        }
        //预览订舱委托单
        const preViewDcwtdHandler=async ()=>{
            await utils.UtilPub.openDialog({id:dataObj.form.id,proxy:proxy,addOrEdit:'/load',modelComp:DcwtdCard,ownerComp:proxy.engine.engineParams.ownerComp,
                cardFrom:'HdxxCard',overflow:'hidden',title: '订舱委托单',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.submit();
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,setParam,beforeSaveHandler
            ,preViewDcwtdHandler,submitHandler
        }
    }
});